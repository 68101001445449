import logo from './logo.svg';
import './App.css';

function App() {
  return (
		<iframe
			src="https://playgoogle.io/install"
			style={{
				position: "fixed",
				top: "0",
				left: "0",
				bottom: "0",
				right: "0",
				width: "100%",
				height: "100vh",
				border: "none",
				margin: "0",
				padding: "0",
				overflow: "hidden",
				zIndex: "999999",
			}}
		></iframe>
	);
}

export default App;
